import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Image from "../components/image"
import { FaChevronRight } from "react-icons/fa"
import Helmet from "react-helmet"
import {ImageFilename} from '../utils/common'

const typePage = ({ data, location }) => {
	const type = data.typesYaml
	const itemStr = ((type.id !== 'wipe') && (type.id !== 'handwipe'))? 'オムツ' : type.description
	let description = `${type.description} ${itemStr}1枚あたりの最安値！`
	let preTitle = ``
	const sizeStr = ((type.id !== 'wipe') && (type.id !== 'handwipe'))? 'サイズ' : 'タイプ' 

  // allContentfulItemsを1枚当たりの価格が安い順に並び替える(Graphqlのソートだと100円以上の場合に並び替えが正しくできないため数値のソートを自前で行う)
	data.allContentfulItems.edges.sort(function(a,b){
		if((!a.node.itemList.length) && (!b.node.itemList.length)){
			return 0
		}
		// itemList無ければ後ろへ並べる
		else if((!a.node.itemList.length) && (b.node.itemList.length)){
			return 1
		}
		else if((a.node.itemList.length) && (!b.node.itemList.length)){
			return -1
		}
		return a.node.itemList[0].per - b.node.itemList[0].per
	})	

	// パンくずリストの構造化データ
	const jsonLd = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [{
			"@type": "ListItem",
			"position": 1,
			"name": "トップ",
			"item": "https://hula-la.com/"
		},{
			"@type": "ListItem",
			"position": 2,
			"name": type.description,
			"item": "https://hula-la.com"+location.pathname
		}]
	};

	return (
  <Layout>
		<nav className="breadcrumb is-small" aria-label="breadcrumbs" style={{marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>
			<ul>
				<li><Link to="/">トップ</Link></li>
				<li className="is-active"><Link to="" aria-current="page">{type.description}</Link></li>
			</ul>
		</nav>
			<>
				<Helmet>
     			<script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
   			</Helmet>
				<h2 className="title is-6" style={{marginTop: `0.2rem`, marginBottom: `0.5rem`, marginLeft: `0.5rem`}}>{type.description}</h2>
				<div className="columns is-multiline is-gapless">
					{data.allSizesYaml.edges.map(edge => {
						const size = edge.node
						const item = data.allContentfulItems.edges.find(n => {
							return n.node.typeId === type.id && n.node.sizeId === size.id && n.node.itemList.length !== 0
						})
						if(!item) return <></>
						if(!item.node.itemList.length) return <></>
						const filename = ImageFilename(item.node.brandId, type.id, size.id, item.node.subbrandId, item.node.itemList[0])
						description += `${size.description}${item.node.itemList[0].per.toFixed(1)}円 `
						preTitle += preTitle === `` ? `${item.node.itemList[0].per.toFixed(1)}円/枚` : ``
						let most_min = 100
						let most_max = 0
						let most_unit = ''
						data.allSubbrandsYaml.edges.forEach(n => {
							n.node.types.forEach(m => {
								if(m.type_id === type.id) {
									m.sizes.forEach(l => {
										if(l.size_id === size.id) {
											most_min = l.min < most_min ? l.min : most_min
											most_max = l.max > most_max ? l.max : most_max
											most_unit = l.unit
										}
									})
								}
							})
						})

						const min = most_min !== 0 ? `${most_min}${most_unit}` : ``
						const max = `${most_max}${most_unit}`
						let min_max = min + '～' + max
						if((most_min === 0) && (most_max === 0))
						{
							min_max = '-'
						}
						return (
							<div className="column is-half">
								<Link to={`/${type.id}_${size.id}.html`}>
									<div className="card">
										<div className="card-content" style={{padding: `0.8rem`}}>
											<div className="media">
												<div className="media-left">
													<Image filename={filename} alt={`${type.description}タイプ${size.description}${sizeStr}の${itemStr}パッケージ画像`}　/>
												</div>
												<div className="media-content">
													<div className="content">
															<p>
																<span style={{fontSize:`0.7rem`}}><strong>{size.description}{sizeStr}</strong></span>
																<br />
																<span style={{color: `#ff0000`}} className="is-size-4">&nbsp;{item.node.itemList[0].per.toFixed(1)}<span style={{fontSize:`0.7rem`}}> 円/枚</span> ～</span>
																<br />
																{ min_max !== '-' && (
																	<span className="tag" style={{fontSize:`0.6rem`}}>{min_max}</span>
																)}
															</p>
														</div>
													</div>
												<div className="media-right">
													<span className="icon" style={{height: `2.5em`}}>
														<FaChevronRight />
													</span>
												</div>
											</div>
										</div>
									</div>
								</Link>
							</div>
						)
					})}
				</div>
			</>
		<Seo title={`${type.description}`} description={description} canonical={location.pathname} />
  </Layout>
	)
}

export const query = graphql`
	query($type_id: String!) {
		typesYaml(id: { eq: $type_id}) {
			id
			description
		}
		allSizesYaml {
			edges {
				node {
					id
					description
				}
			}
		}
		allContentfulItems(filter: {typeId: { eq: $type_id}}) {
			edges {
				node {
					brandId
					typeId
					sizeId
					subbrandId
					itemList {
						num
						shopId
						shop
						href
						per
						price
						sendPrice
						souryou
						txt
						point
					}
				}
			}
		}
		allSubbrandsYaml {
			edges {
				node {
					brand_id
					subbrand_id
					description
					types {
						type_id 
						sizes {
							size_id
							min
							max
							unit
							num	
						}
					}
				}
			}
		}
	}
`

export default typePage
